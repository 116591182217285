import { css } from "@emotion/react";

export interface PageCenterProps {
  children: React.ReactNode;
  absolute?: boolean;
}

export function PageCenter({ children, absolute }: PageCenterProps) {
  const cssAbsolute = absolute
    ? `position: absolute; top: 0; left: 0; width: 100vw; height: 100vh;`
    : "";
  const cssMarginTop = absolute ? "" : `margin-top: 20vh;`;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        ${cssMarginTop}
        ${cssAbsolute}
      `}
    >
      <div>{children}</div>
    </div>
  );
}

export default PageCenter;
