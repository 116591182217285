import React from "react";

export function GridWithTop({
  top,
  bottom,
}: {
  top: React.ReactNode;
  bottom: React.ReactNode;
}) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div>{top}</div>
      <div style={{ display: "grid", overflow: "auto" }}>{bottom}</div>
    </div>
  );
}
