// @ts-nocheck
// FIXME: typescript

// Tools for processing a local file dropped into the browser

function readFile(file): Promise<Blob> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      const content = reader.result as ArrayBuffer;

      resolve(new Blob([content]));
    });
    reader.readAsArrayBuffer(file);
  });
}

async function processFirstFile(fileList): Promise<FileBlobWithName | null> {
  console.log("fileList", fileList);

  if (fileList.length) {
    const file = fileList[0];
    console.log("file", file);
    const blob = await readFile(file);
    return new FileBlobWithName(blob, file.name);
  }

  return null;
}

export class FileBlobWithName {
  constructor(public blob: Blob, public name: string) {}
}

export class DropAreaHandler {
  constructor(public element: HTMLElement) {}

  // If multiple files are dropped, only process the first one
  onDropWithFirstFile(callback) {
    this.element.addEventListener("dragover", (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();
      // Style the drag-and-drop as a "copy file" operation.
      if (!event.dataTransfer) {
        console.warn("event dataTransfer in dragover is blank");
        return;
      }
      event.dataTransfer.dropEffect = "copy";
    });

    this.element.addEventListener("drop", (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();

      if (!event.dataTransfer) {
        console.warn("event dataTransfer in dragover is blank");
        return;
      }

      const fileList = event.dataTransfer.files;

      processFirstFile(fileList).then((file: FileBlobWithName | null) => {
        if (!file) {
          console.warn("No file was processed.");
          return;
        }
        console.log("blob size", file.blob.size);
        callback(file);
      });
    });
  }
}
