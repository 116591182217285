import { css } from "@emotion/css";

type WithStickyFooterProps = {
  footerHeight?: string;
  footerContent: React.ReactNode;
  maxWidth?: string;
  padding?: string;
  children: React.ReactNode;
  mainSectionCss?: string;
  footerSectionCss?: string;
  footerContentCss?: string;
  footerBackground?: string;
  footerColor?: string;
};

export const WithStickyFooter = ({
  children,
  footerHeight,
  footerContent,
  maxWidth,
  padding,
  mainSectionCss,
  footerSectionCss,
  footerContentCss,
  footerBackground,
  footerColor,
}: WithStickyFooterProps) => {
  // Position content both in main and footer sections
  const contentPositioningCss = `
    max-width: ${maxWidth || "100%"};
    margin: 0 auto;
    padding: ${padding || "0 5vw"};
  `;

  footerHeight ??= "10rem";

  return (
    <>
      <div
        className={css`
          box-sizing: border-box;
          ${contentPositioningCss}
          min-height: 100vh;
          margin-bottom: -${footerHeight};
          ${mainSectionCss}
        `}
      >
        <div
          className={css`
            border: solid 1px transparent;
          `}
        >
          {children}
        </div>

        <div
          className={css`
            box-sizing: border-box;
            height: ${footerHeight};
          `}
        ></div>
      </div>

      <div
        className={css`
          box-sizing: border-box;
          width: 100%;
          height: ${footerHeight};
          background: ${footerBackground || "transparent"};
          color: ${footerColor ? "white" : "inherit"};
          ${footerSectionCss}
        `}
      >
        <div
          className={css`
            box-sizing: border-box;
            ${contentPositioningCss}
            ${footerContentCss}
          padding-top: 2rem;
          `}
        >
          {footerContent}
        </div>
      </div>
    </>
  );
};
