import { css } from "@emotion/css";

type DebugFrameProps = {
  title: string;
  children: React.ReactNode;
};

export const DebugFrame = ({ title, children }: DebugFrameProps) => {
  const color = "red";
  return (
    <div
      className={css`
        border: solid 1px ${color};
        border-radius: 3px;
        margin: 1px;
        padding: 1px;
      `}
    >
      <div
        className={css`
          font-size: 9px;
          padding: 1px;
          color: ${color};
        `}
      >
        {title}
      </div>
      {children}
    </div>
  );
};
