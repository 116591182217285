import { debounce } from "@/lib/utils/debounce";
import { css } from "@emotion/react";
import { useRef } from "react";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "../react-contenteditable/contenteditable";

export interface EditableTextProps {
  text: string;
  onChange: (text: string) => void;
}

export function EditableText(props: EditableTextProps) {
  const text = useRef(props.text);
  const el = useRef(null);

  const debouncedOnChange = debounce(500, props.onChange);

  return (
    <div
      css={css`
        [contenteditable]:focus {
          outline: 0 solid transparent;
        }
      `}
      spellCheck={false}
    >
      <ContentEditable
        tagName="div"
        html={text.current}
        onChange={(event) => {
          text.current = sanitizeHtml(event.target.value, {
            allowedTags: [],
            allowedAttributes: {},
          });
          debouncedOnChange(text.current);
        }}
        innerRef={el}
      />
    </div>
  );
}

export default EditableText;
