import { css } from "@emotion/react";

export interface HorizontalEqualSplitlProps {
  children: React.ReactNode;
  gap?: number;
  padding?: number;
}

export function HorizontalEqualSplit({
  children,
  gap,
  padding,
}: HorizontalEqualSplitlProps) {
  return (
    <div
      css={css`
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
        overflow: auto;
        ${typeof gap === "undefined" ? "" : `gap: ${gap}px;`}
        ${typeof padding === "undefined" ? "" : `padding: ${padding}px;`}
      `}
    >
      {children}
    </div>
  );
}

export default HorizontalEqualSplit;
