// @ts-nocheck
// FIXME: typescript

function isSupported(): boolean {
  return (
    typeof window !== "undefined" && typeof window.localStorage !== "undefined"
  );
}
function get(key) {
  if (typeof window === "undefined") return;
  return window.localStorage.getItem(key);
}

function set(key, value) {
  if (typeof window === "undefined") return;
  window.localStorage.setItem(key, value);
}

function getOrGenerate(
  key: string,
  generate: () => string
): string | undefined {
  if (typeof window === "undefined") {
    console.error("window undefined");
    return;
  }

  const existingValue = window.localStorage.getItem(key);
  if (typeof existingValue !== "undefined" && existingValue) {
    console.log("Found:", existingValue);
    return existingValue;
  }

  const newValue = generate();
  console.log("generated", newValue);
  window.localStorage.setItem(key, newValue);

  return newValue;
}

function getInteger(key) {
  const value = get(key);
  if (value) {
    return parseInt(value);
  }
  return null;
}

function getFloat(key) {
  const value = get(key);
  if (value) {
    return parseFloat(value);
  }
  return null;
}

function setBoolean(key, value) {
  set(key, value ? "yes" : "no");
}

function getBoolean(key) {
  const value = get(key);
  if (value === "yes") return true;
  else if (value === "no") return false;
  return null;
}

function setJSON(key, value) {
  set(key, JSON.stringify(value));
}

function getJSON(key) {
  const value = get(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.log("Error while parsing JSON", e);
      return null;
    }
  }
}

function getCustom<T>(
  key: string,
  decoder: (value: string) => T | null
): T | null {
  const value = get(key);
  if (!value) return null;
  return decoder(value);
}

export const localStorageUtils = {
  isSupported,
  get,
  set,
  getOrGenerate,
  getInteger,
  getFloat,
  getBoolean,
  setBoolean,
  getJSON,
  setJSON,
  getCustom,
};
