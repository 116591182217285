import { css } from "@emotion/react";
import React, { Component, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/* eslint-disable-next-line */
export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    // console.error('Caught in ErrorBoundary:', error, );
    return { hasError: true };
  }

  override componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error("Caught in ErrorBoundary:", error, errorInfo);
  }

  override render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          css={css`
            color: #c22;
          `}
        >
          Something went wrong.
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
