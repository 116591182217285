import { localStorageUtils } from "@/lib/utils/localStorageUtils";
import Badge, { BadgeProps } from "@mui/material/Badge";

type NewFeatureBadgeProps = BadgeProps & {
  featureName: string;
};

export const NewFeatureBadge = ({
  featureName,
  children,
  ...badgeProps
}: NewFeatureBadgeProps) => {
  const key = `featureSeen:${featureName}`;
  if (localStorageUtils.getBoolean(key)) {
    return <>{children}</>;
  }

  return (
    <Badge variant="dot" color="success" {...badgeProps}>
      {children}
    </Badge>
  );
};
