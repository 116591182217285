import React, { useEffect, useState } from "react";

export const LoadOnClientSide = function ({
  children,
}: {
  children: React.ReactNode;
}) {
  const [clientSide, setClientSide] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") setClientSide(true);
  }, []);

  return clientSide ? <>{children}</> : <div>Loading...</div>;
};
