import React from "react";

export function GridFullPageFixedTop({
  top,
  bottom,
  margin,
}: {
  top: React.ReactNode;
  bottom: React.ReactNode;
  margin?: number;
}) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "1fr",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        inset: `${margin ?? 0}px`,
      }}
    >
      <div>{top}</div>
      <div
        style={{
          display: "grid",
          overflow: "auto",
          height: "100%",
        }}
      >
        {bottom}
      </div>
    </div>
  );
}
