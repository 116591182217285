import { LegacyRef, useRef } from "react";
import { useEffect } from "react";
import { css } from "@emotion/css";
import { DropAreaHandler, FileBlobWithName } from "@/lib/utils/browser-file";

type FileDropAreaProps = {
  placeholder?: string;
  hint?: string;
  onUpload: (file: FileBlobWithName) => void;
};

export function FileDropArea(props: FileDropAreaProps) {
  const dropAreaRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (dropAreaRef.current) {
      const dropArea = dropAreaRef.current as HTMLDivElement;

      new DropAreaHandler(dropArea).onDropWithFirstFile(
        (file: FileBlobWithName) => {
          props.onUpload(file);
        }
      );
    }
  }, [dropAreaRef.current]);

  return (
    <div
      ref={dropAreaRef as LegacyRef<HTMLDivElement>}
      className={css`
        min-width: 200px;
        min-height: 200px;
        border: dashed 2px #ccc;
        text-align: center;
        padding: 100px;
      `}
    >
      <span
        className={css`
          font-size: 110%;
        `}
      >
        {props.placeholder ?? "Drop the file here"}
      </span>

      {props.hint && (
        <p
          className={css`
            font-size: 90%;
          `}
        >
          {props.hint}
        </p>
      )}
    </div>
  );
}
