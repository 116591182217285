/* eslint-disable prefer-spread */
export function debounce(
  delay: number,
  func: { apply: (...args: any) => void }
) {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
}

export function ratelimit(
  delay: number,
  func: { apply: (...args: any) => void }
) {
  let timer: any;
  let lastT: number | null = null;

  const actualCall = (args: any) => {
    lastT = performance.now();
    func.apply(null, args);
  };
  return (...args: any) => {
    if (!lastT || lastT + delay <= performance.now()) {
      actualCall(args);
      return;
    } else {
      clearTimeout(timer);
      timer = setTimeout(
        () => actualCall(args),
        lastT + delay - performance.now()
      );
    }
  };
}
